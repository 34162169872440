import * as React from "react";
import BookingBlock from "../components/BookingBlock";
import Layout from "../components/Layout";
import Personell from "../components/Personell";
import Seo from "../components/Seo";
import Socials from "../components/Socials";

const ReservationPage = () => {
  return (
    <Layout theme="light" heroSmall>
      <Seo title="Yhteystiedot - Kauneuden ilo" />
      <h2>Yhteystiedot</h2>
      <p>
        Kauneuden ilo sijaitsee Jyväskylän Mäki-Matissa, katutasossa. Meille
        pääset autolla, kävellen ja bussilla, pysäkki on suoraan hoitolan edessä
        sekä parkkipaikkoja on runsaasti hoitolan läheisyydessä. Hoitolan
        takapihalta löytyy myös asiakaspaikkoja. Tarkemmat ohjeet niiden
        löytämiseen löytyvät{" "}
        <a
          href="https://www.instagram.com/reel/CkvDVoiJaOy/?utm_source=ig_web_copy_link"
          target="_blank"
          rel="noreferrer"
        >
          Instagramista videon muodossa
        </a>
        . Voit myös kysellä niitä puhelimitse.
      </p>
      <p>
        Ajanvaraus on mahdollista sähköisen ajanvarauksen kautta 24/7. Voit
        lähestyä myös puhelimitse, viestillä, whatsapilla, Facebook
        messengerissä tai instagramin yksityisviestillä. Mikäli emme heti
        vastaa, olemme tekemässä hoitoja ja soitamme sinulle takaisin heti
        vapauduttuamme.
      </p>
      <ul className="no-style-list">
        <li>Voionmaankatu 8, 40100 JYVÄSKYLÄ</li>
        <li>
          <a href="tel:+358 40 846 4160">040 846 4160</a>
        </li>
        <li>
          <a href="mailto:info@kauneudenilo.fi">info@kauneudenilo.fi</a>
        </li>
      </ul>
      <Socials
        style={{ justifyContent: "left", marginTop: 20, marginBottom: 20 }}
      />
      <Personell />
      <BookingBlock />
    </Layout>
  );
};

export default ReservationPage;
